<template>
    <a-modal :visible="show" :title="$t('editEmail')" width="800px" @cancel="handleCancel">
        <template #footer>
            <a-button key="back" @click="handleCancel">{{$t('cancel')}}</a-button>
            <a-button key="submit" type="primary" :loading="loading" @click="onSubmit">{{$t('ok')}}</a-button>
        </template>
        <a-form ref="formRef" :model="formState" :rules="rules" :colon="false" :label-col="{span:4}"
                :wrapper-col="{span:20}">
            <a-form-item :label="$t('newEmail')" name="newEmail">
                <a-input  v-model:value="formState.newEmail"  />
            </a-form-item>
            <a-form-item :label="$t('pwd2')" name="pwd">
                <a-input-password  v-model:value="formState.pwd" :placeholder="$t('pwdPlz')" />
            </a-form-item>
        </a-form>

    </a-modal>
</template>

<script>
    import {ref, reactive, toRaw, computed} from 'vue'
    import i18n from "../../../locale/main";
    import {message} from 'ant-design-vue'
    import {watch} from 'vue'
    import {useStore} from "vuex";
    import {updateInfo} from '../../../service/user'
    export default {
        emits: ['close'],
        props: {
            show: {
                type: Boolean,
                default: false,
            },
            info: {
                type: Object,
                default:null,
            }
        },
        setup(props,ctx) {
            let t = i18n.global.t
            let store = useStore()
            const info = computed(()=>store.state.user.userInfo)
            const loading = ref(false);
            const formRef = ref();
            const formState = reactive({
                newEmail: '',
                pwd: '',
            });

            watch(()=>props.show,()=>{
                if(props.show){
                    formState.newEmail=''
                    formState.pwd=''
                }
            })
            const onSubmit = async () => {
                try {
                    await formRef.value.validate()

                    loading.value = true;
                    let data=toRaw(formState)
                    await updateInfo({id:info.value.id,acct:data.newEmail,pwd:data.pwd})

                    await store.dispatch('user/setInfo',info.value)

                    loading.value = false;
                    ctx.emit('close', false)
                    message.success(t('updateOk'))
                    formRef.value.resetFields()
                }catch (e) {
                    console.log(e)
                    loading.value = false;
                }
            };

            const validate=(rule,value)=>{
                if(!value){
                    return  Promise.reject(t('editEmailPlz'))
                }
                if(!/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(value)){
                    return Promise.reject(t('editEmailPlz2'))
                }
                return  Promise.resolve()
            }

            const rules = {
                newEmail: [
                    {required: true, validator: validate, trigger: 'blur',},
                ],
                pwd: [{required: true, message: t('login.plzPwd1'), trigger: 'blur',},],
            }
            const handleCancel = () => {
                formRef.value.resetFields()
                ctx.emit('close', false)
            };
            return {
                formState,
                onSubmit,
                rules,
                formRef,
                loading,
                handleCancel,

            };
        }
    }
</script>

<style scoped>

</style>