<template>
    <div class=" flex between align-left paper info-content">
        <div class="flex left align-left left-info">
            <div class="flex column avatar">
                <div class="flex box">{{info?.attr?info?.attr[0]:''}}</div>
                <h4 class="name">{{info?.attr}}</h4>
            </div>
            <div class="info">
                <h4>
                    {{$t('personal.title')}}
                    <span class=" status1" v-if="info.actionState">{{$t('personal.state1')}}</span>
                    <span class=" status2" v-else>{{$t('personal.state2')}}</span>
                </h4>
                <div class="info-box">
                    <div class="flex left item">
                        <span>{{$t('user.acct')}}</span>
                        <span>{{info.acct}}</span>
                    </div>
                    <div class="flex left item">
                        <span>{{$t('user.name')}}</span>
                        <span>{{info.attr}}</span>
                    </div>
                    <div class="flex left item">
                        <span>{{$t('user.type')}}</span>
                        <span>{{info.type}}</span>
                    </div>
                    <div class="flex left item">
                        <span>{{$t('user.extTime')}}</span>
                        <span>{{info.extTime}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="grow-1 right-info">
            <div class="set">
                <h4>{{$t('personal.acctSet')}}</h4>
                <div class="list">
                    <div class="flex between list-item">
                        <div class="flex align-left list-item-left">
                            <div class="cir">
                                <LockOutlined/>
                            </div>
                            <div class="info-bar">
                                <h5>{{$t('personal.pwdEdit')}}</h5>
                                <div>{{$t('pwdPlz4')}}</div>
                            </div>
                        </div>
                        <div class="flex between list-item-right">

                            <a @click="action.pwdShow=true">{{$t('edit')}}</a>
                        </div>
                    </div>
                    <div class="flex between list-item">
                        <div class="flex align-left list-item-left">
                            <div class="cir" style="background: #ED9608;">
                                <MailOutlined/>
                            </div>
                            <div class="info-bar">
                                <h5>{{info.acct}}</h5>
                                <div>{{$t('personal.plz1')}}</div>
                            </div>
                        </div>
                        <div class="flex between list-item-right">
                            <a @click="action.emailShow=true">{{$t('edit')}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <update-pwd :info="info" :show="action.pwdShow" @close="action.pwdShow=false"/>
        <update-email :info="info" :show="action.emailShow" @close="action.emailShow=false" />
    </div>
</template>

<script>
    import {computed, reactive} from 'vue'
    //import i18n from "../../../locale/main";
    import {LockOutlined, MailOutlined} from '@ant-design/icons-vue'
    import UpdatePwd from "./updatePwd";
    import UpdateEmail from "./updateEmail";
    import {useStore} from "vuex";


    export default {
        components: {
            UpdateEmail,
            UpdatePwd,
            LockOutlined,
            MailOutlined,
        },
        setup() {
            let store = useStore()
            //let t = i18n.global.t
            const action=reactive({
                pwdShow:false,
                emailShow:false,
            })

            const info = computed(()=>store.state.user.userInfo)
            return {
                info,
                action,
            }
        }

    }
</script>

<style lang="scss" scoped>
    .info-content{
        padding: 40px;
        height: 400px;
    }
    .avatar{
        width: 150px;
        margin-right: 130px;
        .box{
            width: 150px;
            height: 150px;
            background: rgba(24,114,252,0.2);
            border-radius: 50%;
            font-size: 50px;
            font-weight: 400;
            color: #1890FC;
        }
        .name{
            margin-top: 10px;
        }
    }
    .info{
        width: 400px;
        h4{
            margin-bottom: 40px;
        }
        .status1{
            margin-left: 10px;
            width: 70px;
            height: 24px;
            line-height: 24px;
            text-align: center;
            display: inline-block;
            background: rgba(24, 144, 252, 0.06);
            border: 1px solid #1890FC;
            opacity: 1;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 400;
            color: #1890FC;
        }

        .status2{
            margin-left: 10px;
            width: 70px;
            height: 24px;
            line-height: 24px;
            text-align: center;
            display: inline-block;
            background: rgba(239, 112, 112, 0.06);
            border: 1px solid #EF7070;
            opacity: 1;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 400;
            color: #ef7070;
        }
    }
    .info-box{
        .item{
            font-size: 14px;
            font-weight: 400;
            color: rgba(0,0,0,0.5);
            & span:first-child{
                width: 100px;
            }
        }
    }
    .right-info{
        .set{
            h4{
                margin-bottom: 40px;
            }
            .cir{
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: #2EB1F1;
                color: #ffffff;
                font-size: 18px;
                font-weight: bold;
                text-align: center;
                line-height: 30px;
                margin-right: 10px;
            }
        }
        .list-item{
            padding: 10px 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.090);
            .info-bar{
                h5{
                    font-size: 14px;
                }
                div{
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(0,0,0,0.5);
                }
            }
        }
    }
</style>